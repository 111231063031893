import React from 'react'

function LogoComponent(props) {
    return (
        <svg version="1.1" preserveAspectRatio="xMidYMid meet" viewBox="0.35160425387265803 4.549617065287279 333 333" width="80" height="80">
            <g className="logoPath">
                <path opacity="1" fillOpacity="0" stroke="#1a007f" strokeWidth="24" strokeOpacity="1" d="M183.58 226.03L275.09 314.69" id="bmvjdbJoT"></path>
                <path opacity="1" fillOpacity="0" stroke="#1a007f" strokeWidth="24" strokeOpacity="1" d="M276.69 316.82L108.23 325.66L34.91 248.28" id="fDn65xycB"></path>
                <path opacity="1" fillOpacity="0" stroke="#1a007f" strokeWidth="24" strokeOpacity="1" d="M265.55 324.72L322.35 156.91L279.92 74.68" id="a1eOJySBeA"></path>
                <path opacity="1" fillOpacity="0" stroke="#1a007f" strokeWidth="24" strokeOpacity="1" d="M55.12 46.32L13.35 213.4L16.25 222.14L5.25 227.14L55.84 217.14"></path>
                <path opacity="1" fillOpacity="0" stroke="#1a007f" strokeWidth="24" strokeOpacity="1" d="M45.12 55.32L252.1 16.55L255.6 22.02" id="a4G4uz1o6R"></path>
                <path opacity="1" fillOpacity="0" stroke="#1a007f" strokeWidth="24" strokeOpacity="1" d="M185.27 224L255.6 13.02" id="a4G4uz1o6R"></path>
                <path opacity="1" fillOpacity="0" stroke="#1a007f" strokeWidth="24" strokeOpacity="1" d="M320.69 156.91L210.88 141.12" id="e12IJmAx7O"></path>
                <path opacity="1" fillOpacity="0" stroke="#1a007f" strokeWidth="24" strokeOpacity="1" d="M187.96 224.82L151.13 223.69L102.18 221.13L96.13 124.27L48.79 55.49" id="f1vOBDuiZq"></path>
                <path opacity="1" fillOpacity="0" stroke="#1a007f" strokeWidth="24" strokeOpacity="1" d="M95.4 124.27L169.97 135.5" id="d2w25pOWQX"></path>
                <path opacity="1" fillOpacity="0" stroke="#1a007f" strokeWidth="24" strokeOpacity="1" d="M102.3 222.29L108.73 326.55" id="a3rcFiOlQo"></path>
            </g>
        </svg>
    )
}

export default LogoComponent