import React from 'react'
import { Grid } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import LogoComponent from './LogoComponent'

const MaxGrid = styled(Grid)({
    minHeight: '100vh'
})

function LogoLoader(props) {
    return(
        <MaxGrid container justify='center' alignItems='center'>
            <LogoComponent />
        </MaxGrid>
    )
}

export default LogoLoader