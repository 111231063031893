import React from 'react';
import { HashRouter } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import LogoLoader from './Components/LogoLoader'
import './App.css';

const MainComponent = React.lazy(() => import("./Components/MainComponent"));
const ActivityComponent = React.lazy(() => import("./Components/ActivityComponent"));
const ContactComponent = React.lazy(() => import("./Components/ContactComponent"));
const TechnologyComponent = React.lazy(() => import("./Components/TechnologyComponent"));
const InfoComponent = React.lazy(() => import("./Components/InfoComponent"));

function App() {
  return (
    <div className="App">
      <HashRouter hashType='noslash'>
        <Grid container direction='column'>
          <React.Suspense fallback={<LogoLoader />}>
            <MainComponent />
            <ActivityComponent />
            <TechnologyComponent />
            <ContactComponent />
            <InfoComponent />
          </React.Suspense>
        </Grid>
      </HashRouter>
    </div>
  );
}

export default App;
